import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export const initBlockReviews = () => {
    document.addEventListener('DOMContentLoaded', function () {
        let sliderBlocks = document.querySelectorAll('.blockReviews');

        if (sliderBlocks) {
            sliderBlocks.forEach(sliderBlock => {
                let sliderElem = sliderBlock.querySelector('.blockReviews__reviews');

                const slider = new Splide(sliderElem, {
                    type: "slide",
                    perPage: 2,
                    perMove: 1,
                    gap: 32,
                    arrows: false,
                    pagination: false,
                    padding: {
                        right: 120
                    },
                    breakpoints: {
                        650: {
                            perPage: 1,
                            padding: {
                                right: 180
                            }
                        },
                        460: {
                            perPage: 1,
                            padding: {
                                right: 80
                            }
                        },
                        340: {
                            perPage: 1,
                            padding: {
                                right: 45
                            }
                        }
                    }
                });

                if(window.innerWidth <= 992){
                    slider.mount();
                } else {
                    slider.destroy();
                }
            });
        }
    });
}