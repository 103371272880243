import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export const procedureActiveScroll = () => {
  if(window.innerWidth > 1200) {
    window.addEventListener('DOMContentLoaded', () => {
      const wrapper = document.querySelector('.pageProcedure__welcome')
      const pinned = document.querySelector('.pageProcedure__infoWrapper')
      const pinnedHeight = pinned.clientHeight + 56 + 98 + 64;

      gsap.to(pinned, {scrollTrigger: {
          trigger: wrapper,
          pin: pinned,
          start: 'top 98',
          end: `bottom ${pinnedHeight}`,
          // markers: true,
        }});
    })
  }
}