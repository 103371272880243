import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export const productActiveScroll = () => {
    if(window.innerWidth > 1200) {
        window.addEventListener('DOMContentLoaded', () => {
            const wrapper = document.querySelector('.customProductPage__header')
            const summary = document.querySelector('.customProductPage__summary');
            const pinned = summary.querySelector('.customProductPage__summaryWrapper')
            let pinnedHeight = pinned.clientHeight + 56 + 98;

            let scroll = gsap.to(pinned, {scrollTrigger: {
                trigger: wrapper,
                pin: pinned,
                start: 'top 114',
                end: `bottom ${pinnedHeight}`,
                // markers: true,
            }});

            document.addEventListener('triggerCollapse', () => {
                scroll.scrollTrigger.refresh();
            })
        })
    }
}