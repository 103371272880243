export const initCategoriesGallery = () => {
    const galleries = document.querySelectorAll('.blockCategoriesGallery');

    if(galleries){
        galleries.forEach(gallery => {
            const items = gallery.querySelectorAll('.blockCategoriesGallery__gallery--item');
            let count = items.length;
            const moreBtn = gallery.querySelector('.show_more');

            if(count < 3){
                moreBtn.style.display = "none";
            }

            moreBtn.addEventListener('click', () => {
                for(let i = 0; i < count; i++){
                    items[i].style.display = "block";
                }
                moreBtn.style.display = "none";
            });
        })
    }
}