import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export const initBlockProcedureHeader = () => {
    document.addEventListener('DOMContentLoaded', function () {
        let sliderBlocks = document.querySelectorAll('.blockProcedureHeader');

        if (sliderBlocks) {
            sliderBlocks.forEach(sliderBlock => {
                let sliderElem = sliderBlock.querySelector('.blockProcedureHeader__slider');

                const slider = new Splide(sliderElem, {
                    type: "slide",
                    perPage: 3,
                    perMove: 1,
                    gap: 32,
                    arrows: false,
                    pagination: false,
                    padding: {
                        right: 120
                    },
                    breakpoints: {
                        768: {
                            perPage: 1,
                            padding: {
                                right: 150
                            }
                        },
                        460: {
                            perPage: 1,
                            padding: {
                                right: 100
                            }
                        }
                    }
                });

                if(window.innerWidth <= 992){
                    slider.mount();
                } else {
                    slider.destroy();
                }
            });
        }
    });
}