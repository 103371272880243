import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export const initBlockSlider = () => {
    document.addEventListener('DOMContentLoaded', function () {
        let sliderBlocks = document.querySelectorAll('.blockSlider');

        if (sliderBlocks) {
            sliderBlocks.forEach(sliderBlock => {
                let sliderElem = sliderBlock.querySelector('.blockSlider__sliderWrapper');
                let navigation = sliderBlock.querySelector('.blockSlider__navigation');

                const slider = new Splide(sliderElem, {
                    type: "slide",
                    perPage: 3,
                    perMove: 1,
                    gap: 32,
                    arrows: false,
                    pagination: false,
                    fixedWidth: 300,
                    // padding: {
                    //     right: 120
                    // },
                    breakpoints: {
                        // 1200: {
                        //     padding: {
                        //         right: 90
                        //     }
                        // },
                        992: {
                            perPage: 2,
                            // padding: {
                            //     right: 120
                            // }
                        },
                        650: {
                            perPage: 1,
                            // padding: {
                            //     right: 180
                            // }
                        },
                        460: {
                            perPage: 1,
                            // padding: {
                            //     right: 80
                            // }
                        }
                    }
                });

                let prev = navigation.querySelector('.blockSlider__navigation--prev');
                let next = navigation.querySelector('.blockSlider__navigation--next');

                prev.addEventListener('click', () => {
                    slider.go('<');
                })
                next.addEventListener('click', () => {
                    slider.go('>');
                })
                slider.on('move', e => {
                    if(e === 0) prev.classList.add('disable');
                        else prev.classList.remove('disable');

                    if(window.innerWidth <= 576){
                        if(e === Math.round(slider.length / slider.options.perPage) - 1) next.classList.add('disable');
                            else next.classList.remove('disable');
                    } else{
                        if(e === Math.round(slider.length / slider.options.perPage)) next.classList.add('disable');
                            else next.classList.remove('disable');
                    }
                })
                slider.on('mounted', () => {
                    if(slider.length <= slider.options.perPage){
                        navigation.style.display = "none";
                        slider.options.drag = false;
                        slider.refresh();
                    }
                })
                slider.mount();
            });
        }
    });
}