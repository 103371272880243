import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export function productCustomGallery() {
  document.addEventListener( 'DOMContentLoaded', function () {
    let sliders = document.querySelector('.customProductPage__gallery');
    if(sliders){
      let primary = sliders.querySelector('.splidePrimary');

      let primarySlider = new Splide(primary, {
        type: 'fade',
        pagination: false,
        arrows: false,
        autoHeight: true,
      });

      if(sliders.querySelector('.splideNav')) {
        let secondary = sliders.querySelector('.splideNav');
        let secondarySlider = new Splide( secondary, {
          fixedWidth : 106,
          fixedHeight: 127,
          gap        : 16,
          perPage    : 4,
          perMove    : 1,
          rewind     : true,
          cover      : true,
          isNavigation: true,
          pagination : false,
        } );

        secondarySlider.on('mounted', ()=>{
          if(secondarySlider.length <= secondarySlider.options.perPage ) {
            secondarySlider.options = { arrows: false, pagination: false, drag: false };
            secondarySlider.destroy();
            secondarySlider.mount();
          }
        })

        secondarySlider.mount();
        primarySlider.sync(secondarySlider).mount();
      } else {
        primarySlider.mount();
      }
    }
  });
}