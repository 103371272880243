export const initCollapseMenu = () => {
    const collapses = document.querySelectorAll('.collapse__menu');
    const triggerCollapse = new Event('triggerCollapse');

    if(collapses){
        collapses.forEach(collapse => {
            let items = collapse.querySelectorAll('.collapse__menu--item');

            if(items){
                items.forEach(item => {
                    let toggle = item.querySelector('.collapse__menu--item__title');
                    let content = item.querySelector('.collapse__menu--item__description');

                    toggle.addEventListener('click', () => {
                        items.forEach(elem => {
                            if(elem !== item){
                                let contentElem = elem.querySelector('.collapse__menu--item__description');
                                elem.classList.remove('opened');
                                contentElem.style.maxHeight = null;
                            }
                        })
                        item.classList.toggle('opened');
                        if (content.style.maxHeight) content.style.maxHeight = null;
                        else{
                            content.style.maxHeight = content.scrollHeight + "px";
                            setTimeout(() => {
                                window.scrollTo({
                                    top: item.offsetTop - 98,
                                    left: 0,
                                    behavior: 'smooth'
                                })
                            }, 300)
                        }
                        setTimeout(() => {
                            document.dispatchEvent(triggerCollapse);
                        }, 300)
                    });
                })
            }
        })
    }
}