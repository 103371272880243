export const initWidgetFilterProducts = () => {
    const widget = document.querySelector('.shopFiltersWidget');

    if(widget){
        const items = widget.querySelectorAll('.shopFiltersWidget__item');
        const confirm = widget.querySelector('button');
        const filterRemovals = document.querySelector('.shopFiltersWidget__filterRemove');
        const mobileOpener = document.querySelector('.shopFiltersWidget--mobileBtn');
        const baseShopUrl = widget.getAttribute('data-shop-url');

        if(items){
            items.forEach(item => {
                const showMore = item.querySelector('span.show-more');
                const links = item.querySelectorAll('.shopFiltersWidget__item--link');
                if(showMore){
                    showMore.addEventListener('click', () => {
                        links.forEach(link => {
                            link.style.display = "block";
                        })
                        showMore.style.display = "none";
                    })
                }
            })

            confirm.addEventListener('click', () => {
                let selected = [];
                const urlParams = new URLSearchParams(window.location.search);
                items.forEach(item => {
                    const links = item.querySelectorAll('.shopFiltersWidget__item--link');
                    links.forEach(link => {
                        let input = link.querySelector('input');
                        if(input.checked === true){
                            selected.push(input.getAttribute('data-category'));
                        }
                    });
                });
                selected.length > 0 ? urlParams.set('categories', selected.join(',')) : urlParams.delete('categories');
                window.location = urlParams.toString() ? baseShopUrl+'?'+urlParams : baseShopUrl;
            });

            if(filterRemovals){
                const removeBtns = filterRemovals.querySelectorAll('.shopFiltersWidget__filterRemove--item');
                if(removeBtns){
                    removeBtns.forEach(btn => {
                        let remove = btn.querySelector('span');
                        remove.addEventListener('click', () => {
                            let currentCategory = btn.getAttribute('data-category');
                            const urlParams = new URLSearchParams(window.location.search);
                            let categories = urlParams.get('categories').split(',');
                            let index = categories.indexOf(currentCategory);
                            if(index !== -1){
                                categories.splice(index, 1);
                            }
                            categories.length > 0 ? urlParams.set('categories', categories.join(',')) : urlParams.delete('categories');
                            window.location = urlParams.toString() ? baseShopUrl+'?'+urlParams : baseShopUrl;
                        });
                    })
                }
            }
        }
        if(mobileOpener){
            const mobileClose = widget.querySelector('.modal-close');
            mobileOpener.addEventListener('click', () => {
                widget.classList.add('opened');
            });
            mobileClose.addEventListener('click', () => {
                widget.classList.remove('opened');
            });
        }
    }
}